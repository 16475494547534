import service from './request'
//接口前缀
const Report_PREFIX = '/report';
/* -----------------  结算报表模块 ---------------------- */
//车辆和司机结算清单
const ListSettlements = data=>{
  return service({
    url: `${Report_PREFIX}/settlements/listSettlements`,
    method: 'post',
    data
  })
}
//车辆和司机结算清单-2022.12.29-新版司机结算清单
const ListSettlementsNew = data=>{
  return service({
    url: `${Report_PREFIX}/settlements/newListSettlements`,
    method: 'post',
    data
  })
}
//线路匹配驾驶员
const DriverqueryByCompanyId = data=>{
  return service({
    url: `base/driver/queryByCompanyId/${data}`,
    method: 'get',
    data
  })
}
//司机考勤查询
const DriverqueryTimes = (curPage,pageSize,data)=>{
  return service({
    url: `${Report_PREFIX}/driverLoginLog/getByQueryParam/${curPage}/${pageSize}`,
    method: 'post',
    data
  })
}
//司机考勤查询-新
const queryBusCheckupByPage = (data)=>{
  return service({
    url: `${Report_PREFIX}/driverLoginLog/queryBusCheckupByPage`,
    method: 'post',
    data
  })
}


export {
  ListSettlements,//车辆和司机结算清单
  ListSettlementsNew,//司机结算清单-新
  DriverqueryByCompanyId,//线路匹配驾驶员
  DriverqueryTimes,//司机考勤查询
  queryBusCheckupByPage,//司机考勤--新
}

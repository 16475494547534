<template>
  <div class="time-sheet-driver">
    <div class="record-centent">
      <div class="form-area table-search">
        <el-form
          class="demo-form-inline"
          ref="form"
          :model="form"
          label-position="left"
          label-width="82px"
          :inline="true"
          :rules="rules"
        >
          <el-form-item label="开始时间：" prop="startTime">
            <el-date-picker
              v-model="form.startTime"
              type="date"
              placeholder="选择开始时间"
              :clearable="false"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间：" prop="endTime">
            <el-date-picker
              v-model="form.endTime"
              type="date"
              placeholder="选择结束时间"
              :clearable="false"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="所属线路：">
            <el-select
              v-model="form.companyId"
              placeholder="请选择线路"
              @change="checBusDriverByline"
            >
              <el-option
                v-for="item in busLineList"
                :key="item.companyId"
                :label="item.companyName"
                :value="item.companyId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="选择司机：">
            <el-select
              clearable
              v-model="form.driverIdList"
              multiple
              collapse-tags
              filterable
              placeholder="请选择司机"
            >
              <el-option
                v-for="item in driversOptions"
                :key="item.credentialsNo"
                :label="item.driverName"
                :value="item.credentialsNo"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="alarm-btn">
            <el-button size="small" type="primary" @click="search"
              >查询</el-button
            >
            <el-button size="small" type="primary" @click="reset"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- 日报月报 -->
      <el-tabs
        type="border-card"
        v-model="form.reportType"
        @tab-click="clickTab"
      >
        <el-tab-pane label="日报" name="1">
          <el-row class="driver-manage-box">
            <el-col :span="24" class="table-box">
              <el-table
                :data="tableData"
                :header-cell-style="{ background: '#fff', color: '#999999' }"
                stripe
                height="calc(100vh - 360px)"
              >
                <el-table-column
                  type="index"
                  label="序号"
                  width="50"
                ></el-table-column>
                <el-table-column
                  prop="lineName"
                  label="所属线路"
                ></el-table-column>
                <el-table-column
                  prop="numberPlate"
                  label="车牌号"
                  width="110"
                ></el-table-column>
                <el-table-column
                  prop="driverName"
                  label="刷卡司机"
                ></el-table-column>
                <el-table-column
                  prop="credentialsNo"
                  label="身份证号"
                  width="170"
                ></el-table-column>
                <el-table-column
                  prop="inTime"
                  label="登签时间"
                  :show-overflow-tooltip="true"
                ></el-table-column>
                <el-table-column
                  prop="outTime"
                  label="退签时间"
                  :show-overflow-tooltip="true"
                ></el-table-column>
                <el-table-column
                  prop="workTime"
                  label="工作时长"
                ></el-table-column>
                <el-table-column
                  prop="runningTime"
                  label="运行时长"
                ></el-table-column>
              </el-table>
            </el-col>
          </el-row>
          <my-page
            ref="pagination"
            @sendInfo="currentPageChange"
            :pageTotal="total"
            class="pagination"
          ></my-page>
        </el-tab-pane>
        <el-tab-pane label="月报" name="2">
          <el-row class="driver-manage-box">
            <el-col :span="24" class="table-box">
              <el-table
                :data="tableData"
                :header-cell-style="{ background: '#fff', color: '#999999' }"
                stripe
                height="calc(100vh - 360px)"
              >
                <el-table-column
                  type="index"
                  label="序号"
                  width="50"
                ></el-table-column>
                <el-table-column
                  prop="workMonth"
                  label="月份"
                ></el-table-column>
                <el-table-column
                  prop="lineName"
                  label="所属线路"
                ></el-table-column>
                <el-table-column
                  prop="numberPlate"
                  label="车牌号"
                ></el-table-column>
                <el-table-column
                  prop="driverName"
                  label="刷卡司机"
                ></el-table-column>
                <el-table-column
                  prop="idCard"
                  label="身份证号"
                  width="170"
                ></el-table-column>
                <el-table-column
                  prop="wordDayCount"
                  label="出勤天数"
                ></el-table-column>
              </el-table>
            </el-col>
          </el-row>
          <my-page
            ref="pagination"
            @sendInfo="currentPageChange"
            :pageTotal="total"
            class="pagination"
          ></my-page>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { queryAllBusLineList } from '@/api/lib/bus-api.js';
import { ListSettlements, DriverqueryByCompanyId, DriverqueryTimes } from '@/api/lib/report-api.js';
import pagination from '@/components/pagination/pagination.vue'
import moment from 'moment'
import {
  getStartDate,
  getCurentDate,
  checkTimeLimit,
  checkLimitDate,
  formatDate,
  formatDict,
  getCurent
} from '@/common/utils/index'
export default {
  components: {
    'my-page': pagination,
  },
  data () {
    return {
      form: {
        currentPage: 1,
        pageSize: 10,
        startTime: new Date(getStartDate()), // 开始时间
        endTime: new Date(getCurentDate()), // 结束时间
        companyId: '',//选择线路
        driverIdList: [],//选择司机
        reportType: '1',//1日报2月报
      },
      total: 0,//总条数
      busLineList: [],//线路列表
      driversOptions: [],//驾驶员列表
      tableData: [],
      rules: {
        startTime: [
          { required: true, message: '请选择', trigger: 'blur' },
        ],
        endTime: [
          { required: true, message: '请选择', trigger: 'blur' }
        ]
      },
      pickerOptions: {
        disabledDate (time) {
          return checkLimitDate(time)
        },
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
    }
  },
  created () {
    this.queryAllBusLine();//获取所有线路
  },
  methods: {
    //根据线路选择司机
    checBusDriverByline () {
      var self = this;
      self.form.driverIdList = [];
      self.driversOptions = [];
      DriverqueryByCompanyId(self.form.companyId)
        .then(res => {
          if (res.data.length == 0) {
            // self.$message.warning("所有司机都已绑定，当前无司机可选择");
          } else {
            self.driversOptions = res.data;
            res.data.forEach(element => {
              self.form.driverIdList.push(element.credentialsNo)
            });
          }
        })
        .catch(res => { });
    },
    clickTab (tab, event) {
      this.tableData = [];
      this.form.reportType = tab.name
      this.form.currentPage = 1;
      this.getAlarmSettingListFn()
    },
    reset () {
      this.form = {
        currentPage: 1,
        pageSize: 10,
        startTime: new Date(getStartDate()), // 开始时间
        endTime: new Date(getCurentDate()), // 结束时间
        companyId: '',//选择线路
        driverIdList: [],//选择司机
        reportType: '1',//1日报2月报
      }
    },
    getAlarmSettingListFn () {
      let { currentPage, pageSize } = this.form;
      const data = { ...this.form }
      data.startTime = moment(data.startTime).format("YYYY-MM-DD"),
        data.endTime = moment(data.endTime).format("YYYY-MM-DD")
      delete data.currentPage
      delete data.pageSize
      DriverqueryTimes(currentPage, pageSize, data).then(res => {
        if (res.code === 1000) {
          this.tableData = res.data.result;
          this.total = res.data.pageVO.totalCount;
        }
      })
    },
    search () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.startTime > this.form.endTime) {
            this.$message.warning("开始日期不能大于结束日期");
          } else {
            this.form.currentPage = 1;
            this.getAlarmSettingListFn();
          }
        }
      })
    },
    //获取所有线路
    queryAllBusLine () {
      queryAllBusLineList().then((res) => {
        if (res.code === 1000) {
          if (res.data.length == 0) {
            this.$message.warning("还没有线路信息，请先添加线路信息");
          } else {
            this.busLineList = res.data
          }
        }
      })
    },
    // 分页参数改变
    currentPageChange (val) {
      let { pageSize, currentPage } = val;
      this.form.currentPage = currentPage;
      this.form.pageSize = pageSize;
      this.getAlarmSettingListFn()
    },
  }
}
</script>
<style lang="scss" scoped>
.time-sheet-driver {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.time-sheet-driver {
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
}
</style>